<template>
	<v-form class="px-4">
			<v-row class="list" align="center">
				<v-col cols="10" sm="10" no-gutters>
					<p>通知</p>
					<span>店舗からのプッシュ通知を受け取ります。</span>
				</v-col>
				<v-col cols="2" sm="2" no-gutters>
					<v-switch v-model="pushSwitch" inset></v-switch>
				</v-col>
			</v-row>
	</v-form>
</template>

<script>
export default {
	data() {
		return {
			pushSwitch: (this.storageGet('setting_push_flg') == 1),
		}
	},
    mounted () {
      this.init = this.storageGet('*')
    },
    watch: { //init apiが読み込まれたか監視
      '$root.init_flg': 'fetchData',
      'pushSwitch': 'settingPush',
    },
    methods: {
      fetchData(){ //init apiが読み込まれた場合initに値を入れる
        this.init = this.storageGet('*')
      },
      async settingPush() {
        try {
          //loadingを表示
          this.loading = true
          //念の為もう一度ローカルから取得（後ほど処理変える）
          this.init = this.storageGet('*')

          /*  push 通知ON/OFF API実行 */
          this.pushSwitch? this.setting_push_flg = 1 : this.setting_push_flg = 0
          var params = {
            'device_uid': this.init.device_uid,
            'sid': this.init.sid,
            'key': this.init.key,
            'device_id': this.init.device_id,
            'os': this.init.os,
            'flg': this.setting_push_flg
          };
          var res = await this.apiCall('/user/pushSetting', params, 'post');
          if (!res) {
            this.loading = false
            return false
          }
          this.storageSave(JSON.stringify({
            'setting_push_flg': res.setting_push_flg,
          }))

        } catch(e) {
          this.loading = false
          this.callDialog('システムエラー', '予期せぬエラーを検知しました', 3);
        }
      }
    }

}
</script>

<style scoped>
p {
	font-weight: bold;
	font-size: 14px;
	margin-bottom: 0;
}

span {
	font-weight: 500;
	font-size: 12px;
	margin-bottom: 0;
}
.list {
	border-bottom: 1px solid #DEDEDF;
}
</style>
